import React, { useState, useEffect } from "react";
import { useAccount,usePrepareContractWrite, useContractWrite, useWaitForTransaction } from "wagmi";
import { ethers } from "ethers";
import * as Abiconfig from './Abiconfig';
import Supermint from './TarotV2/MintButton';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { useLocation, useNavigate } from 'react-router-dom';


const contractAddress = "0x7fE94fC58Ee5A9D7fe758e7342191a3665301D1E"; 
const contractABI = Abiconfig.ABI; 
const expectedChainId = 8453; 

const MyComponent = (MsgSender) => {

  
  const [Asked, setAsked] = useState(false);
  const [question, setQuestion] = useState("");
  const [PepeAnswer, setPepeAnswer] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [CardNo, setCardNo] = useState("");
  
  const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // get ref params from link address
    const queryParams = new URLSearchParams(location.search);
    const referralParam = queryParams.get('ref');
    const referralSpecial = queryParams.get('page');

    if (referralSpecial==='ask') {setReferral('0x484EA01376465a8ddB0932667Cdcde5563D25eC9'); }
    if (referralParam && ethers.utils.isAddress(referralParam)) {
      
      setReferral(referralParam);
    } else {
            //delete from address
            queryParams.delete('ref');
            navigate({ search: `?${queryParams.toString()}` }, { replace: true });
            } 
}, [location.search, navigate]);
////////////



    
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();
    const { isConnected } = useAccount();

    const handleSwitchNetwork = () => {
        // change to network ID
        switchNetwork(expectedChainId);
    };


////////////////////////Animation/////////////////////
function  animateText(element) {
  const string = element.innerHTML;
  element.innerHTML = string.replace(/./g, '<span class="new">$&</span>');

  const spans = element.querySelectorAll('span.new');
  spans.forEach((el, i) => {
    setTimeout(() => {
      el.classList.add('div_opacity');
    }, 70 * i);
  });
}
  /////////////////////////////////////////////////////////





  useEffect(() => {
    const iframe = document.querySelector('iframe'); 
    if (iframe && iframe.contentDocument) {
      if (PepeAnswer!=='') {
      iframe.contentDocument.getElementById('startt').style.visibility = 'hidden';
      iframe.contentDocument.querySelector('.card-back').classList.add(`card${CardNo}`);
      iframe.contentDocument.getElementById('MInput').value = question;
      iframe.contentDocument.getElementById("MInput").readOnly = 'true';
      iframe.contentDocument.getElementById('cardShow').style.visibility = "visible";
			iframe.contentDocument.querySelector('.card').classList.add('flip');
      
      iframe.contentDocument.getElementById('MInput').style.visibility = 'visible';	
      iframe.contentDocument.getElementById('clickToAsk').style.visibility = 'hidden';
      iframe.contentDocument.getElementById('example').innerHTML = PepeAnswer;
      iframe.contentDocument.getElementById('example').style.visibility = 'visible';
      iframe.contentDocument.getElementById('example').style.alignContent = 'normal';
        
                      
                      const exampleElement = iframe.contentDocument.getElementById('example');
                      animateText(exampleElement);
                    }; 
                                          }
      } , [PepeAnswer, CardNo, question]); 



  
  const {config} = usePrepareContractWrite({
    address: contractAddress,
    abi: contractABI,
    functionName: "ask",
    overrides: {
      value: ethers.utils.parseEther('0.0002'), 
    },
  });

  const { 
  data: askData,
  write: askQuestion,
  isLoading: isAskLoading,
  isSuccess: isAskSuccess } =  useContractWrite(config)

  //wait for end of transaction getAnswer
  const { isLoading } = useWaitForTransaction({
    hash: askData?.hash,
    onSuccess: async () => { 
    if (!Asked) {

        // generate random 0 до 21
let rndCard = Math.floor(Math.random() * 22) ;
if (rndCard===0){rndCard=22;};
setCardNo (rndCard);


const tarotCards = [
    'The World',         // 0
    'The Fool',          // 1
    'The Magician',      // 2
    'The High Priestess',// 3
    'The Empress',       // 4
    'The Emperor',       // 5
    'The Hierophant',    // 6
    'The Lovers',        // 7
    'The Chariot',       // 8
    'The Force',         // 9
    'The Hermit',        // 10
    'The Wheel of Fortune', // 11
    'The Justice',       // 12
    'The Hanged Man',    // 13
    'The Death',         // 14
    'The Temperance',    // 15
    'The Devil',         // 16
    'The Tower',         // 17
    'The Star',          // 18
    'The Moon',          // 19
    'The Sun',           // 20
    'The Judgement',     // 21
    'The World'          // 22
      ];

       const cardis = tarotCards[rndCard];      
       console.log(rndCard, 'Card:', cardis);

     
      const questis = question; 

      try {
        const apiKey = 'YFTGfhjT*h463gHnckf92hgfj60HHf%4dsjz,aeo09wj';
    
        const response = await fetch(`https://scrolls.chat:3001/api?card=${cardis}&quest=${questis}`, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json', 
                'x-api-key': apiKey 
            }
        });
        
        if (!response.ok) {
          throw new Error('error API');
        }

        const data = await response.json();
        console.log('Answer', data.answer);
        setPepeAnswer (`${cardis} card! ${data.answer}`);
        setIsMinting(true);
        if (PepeAnswer!=='') {setAsked(true);};
          
      } catch (error) {
        console.error('Server answer error:', error);}

      }


        
      
      

    },
  });

  
  return (
    <div className='askDiv'>


{ chain?.id !== expectedChainId && isConnected &&( <button className='w-button-network' onClick={handleSwitchNetwork}> CHANGE NETWORK</button> )
                }



      {!isMinting && <textarea className='inputAsk' maxLength={70}
        type="text"
        placeholder="Type in here to ask Pepe the Soothsayer a question."
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />}
      <br/>
      {!isMinting &&  <button className='w-button-ask' onClick={() => askQuestion()} disabled={isAskLoading || isLoading || chain?.id !== expectedChainId ||  question.length < 5}>
        {isLoading || isAskLoading  ? "SENDING..." : "ASK"}
      </button>}

      {isAskSuccess && !isMinting && <p>Pending transaction confirmation...</p>}
        
      {isMinting && (
        <Supermint cardNoIN={CardNo} questionIN={question} answerIN={PepeAnswer} referralIN={referral} MsgSenderIN={MsgSender}/>
      )}


               


    </div>
  );
};

export default MyComponent;
