import "./styles/main.css";
import '@rainbow-me/rainbowkit/styles.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route,useLocation } from "react-router-dom";

import {  getDefaultWallets,  RainbowKitProvider, lightTheme,darkTheme, Chain  } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig,useAccount } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { mainnet, sepolia, base } from 'wagmi';
import ButtonR from "./components/Buttonrain.js";
import { BrowserRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MintV2 from './components/MintVer2';


 
const BaseL2 = {
  id: 8453,
  name: 'Base Mainet',
  network: 'Base',
  iconUrl: 'https://assets.deform.cc/production/12c29135-4046-4be9-aecb-789476cbf657.png',
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Base',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org'] },
    default: { http: ['https://mainnet.base.org'] },
  },
  blockExplorers: {
    default: { name: 'Basescan', url: 'https://basescan.org' },
    etherscan: { name: 'Basescan', url: 'https://basescan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};


const Scroll = {
  id: 534352,
  name: 'Scroll Mainnet',
  network: 'Scroll',
  iconUrl: 'https://scroll.io/logo.png',
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Scroll',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.scroll.io'] },
    default: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    default: { name: 'ScrollScan', url: 'https://scrollscan.com' },
    etherscan: { name: 'ScrollScan', url: 'https://scrollscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};

//  Sepolia 
const sepolia2 = {
  id: 11155111,
  name: 'Sepolia',
  network: 'sepolia',
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc2.sepolia.org'], // 
    },
  },
  blockExplorers: {
    default: { name: 'Etherscan', url: 'https://sepolia.etherscan.io' },
  },
  testnet: true,
};


const { provider, chains } = configureChains(
  [BaseL2],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'RainbowKit Mint NFT Demo',
  projectId: '9c64e367da4b5b6e2af8b386d0b21b19',
  chains,
});


const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})



const App = () => {
   // const location = useLocation();
   const { address, isConnected } = useAccount();

    return (
      <BrowserRouter>
        <WagmiConfig client={wagmiClient} > 
        <RainbowKitProvider initialChain={8453}  chains={chains} modalSize="compact"  theme={lightTheme({ accentColor: '#b7a67c', accentColorForeground: 'black',  borderRadius: 'medium', })}>
        
         
        
<div className="navbar-logo-center">

	<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-logo-center-container shadow-three w-nav" >
	<div className='reffnavbar'>
  {isConnected  && 
          <CopyToClipboard text={`https://grandpepe.xyz/?ref=${address}`}>
            <button className="buttonShare w-button">
            <img src="ios_share_icon.png" alt="refferal link"/>
            </button>
          </CopyToClipboard>
       }
  <ButtonR></ButtonR>   
  </div> 
     
			<div className="container-2">
				<div className="navbar-wrapper-three">
					<h1 className="heading">The Grand Pepe's Tarot</h1>
				</div>
			</div>
		<div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
	</div>
</div>
	<div className="w-layout-blockcontainer container w-container">
		
		<div className="div-left">
      <a className="opensea-logo" href="https://opensea.io/assets/base/0x7fe94fc58ee5a9d7fe758e7342191a3665301d1e/1" target="_blank" rel="noopener noreferrer">
        <img  src="OPENSEA_LOGO001.png" alt="Grand Pepe on Opensea" />
      </a>
      <iframe className="div-block-frame" src="Tarot005.html" title="description"/>
      <div className='div-loading'> Loading...</div>
		</div>		
		<div className="div-block-3">
				<div className="text-block">If you don't make life-changing decisions without outside advice, then this is what you've been looking for.<br></br><br></br>Thinking about getting married, buying a house, going on vacation or can't decide on a pizza?<br></br>
				<br></br>The Grand Pepe answers any your question using the great power of tarot cards.<br></br> And you can mint his answer as NFT token.<br></br>
				</div>
					
          
          
              <MintV2 MsgSender={address}/>
           
          
         
				
		</div>
	</div>
	<div className="w-layout-blockcontainer container w-container" >
		<div className="text-block">
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		<p className='bigp'>FAQ: </p><br/><br/>
What is 'The Grand Pepe's Tarot'?
<p className='darkp'>Its interactive NFT project with tarot AI inside on Base L2</p><br/>

How to ask a question?
<p className='darkp'>One visit - one question. <br/>
                      Type your question by clicking at the bottom. After click <br/>
                      on the Ask button and Grand Pepe will shuffle his deck and <br/>
                      then give his answer after seeing the drawed card. 
                      <br/>And now you can mint his answer as NFT token</p><br/>
				
What is the ASK price?
<p className='darkp'>0.0002 eth</p><br/>

What is the MINT price?
<p className='darkp'>0.001 eth</p><br/>



Will there be a The Grand Pepe Discord?
<p className='darkp'>If we are talking about official, then there won’t be.</p><br/>
<br/><br/><br/><br/><br/><br/>
<p className='twitterp'><a className='twitterlink' href="https://x.com/Pepesoothsayer" target="_blank" >X</a></p>
		</div>
				
    
    <img src="http://canarytokens.com/articles/images/rspixj7uf75tkukzuqjj6wv8p/contact.php" />
	</div>
          
          {/*  <Footer/>  */}   
          </RainbowKitProvider>    
        </WagmiConfig>
        </BrowserRouter>
    );  


}


export default App;



