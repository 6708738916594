import React, { useState, useEffect } from "react";
import { useAccount,usePrepareContractWrite, useContractWrite, useWaitForTransaction, useContractEvent } from "wagmi";
import { ethers } from "ethers";
import * as Abiconfig from '../Abiconfig'


const contractAddress = "0x7fE94fC58Ee5A9D7fe758e7342191a3665301D1E"; 
const contractABI = Abiconfig.ABI;
const apiKey = 'Wsdg^4hG%gdnBdj*9eNlop(7vdsZ!rtjJ';

const MintAnswer = ({ cardNoIN, questionIN, answerIN, referralIN, MsgSenderIN}) => {
  
  
  const [PepeAnswer, setPepeAnswer] = useState("");
  const [isMinting, setIsMinting] = useState(false);  
  const [tokenId, setTokenId] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

    
  // function button mintNFT
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: contractABI,         
    functionName: "mint",
    overrides: {value: ethers.utils.parseEther('0.001')},
    args: [ethers.utils.getAddress(referralIN)], 
    
  });

 

  const {
    data: mintData,
    write: mintNFT,
    isLoading: isMintLoading,
    isSuccess: isMinted,
  } = useContractWrite(config);

// whait for end of transaction
const {  isLoading } = useWaitForTransaction({
  hash: mintData?.hash,
  onSuccess: async (receipt) => {
    setIsVisible (true);
    // looking for "Minted" in transaction logs
    const mintedEvent = receipt.logs
      .map(log => {
        try {
          return new ethers.utils.Interface(contractABI).parseLog(log);
        } catch {
          return null;
        }
      })
      .find(log => log && log.name === "Minted");

    if (mintedEvent) {
      let [msgSender, mintedTokenId] = mintedEvent.args;        
        mintedTokenId = mintedTokenId.sub(1).toString(); // minus -1 
        console.log("mintedTokenId:", mintedTokenId);

        setTokenId(mintedTokenId);
        console.log("To Metadata tokenId:", tokenId);
      // Save metadata to server logs
      await saveMetadata(mintedTokenId);
    } else {
      console.error("cant find 'Minted' in logs.");
    }
  },
  onError(error) {
    console.error("transaction error", error);
  },
});
  
  const saveMetadata = async (tokenId) => {
    
    const data = {
      tokenId: tokenId,
      question: questionIN,
      answer: answerIN, // answer
      card: cardNoIN, // card details
  };

    // send to server
    const url = 'https://scrolls.chat:3011/create-metadata'; 
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-api-key': apiKey, // add api key
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Metadata restore ERROR");
      }

      console.log(`NFT Metadata created for token ${tokenId} successfuly.`);
    } catch (error) {
      console.error("Metadata restore ERROR:", error);
    }

    
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // hide after 10 sec
    }, 10000);
    return () => clearTimeout(timer); // clear timer
  }, [isVisible]); // empty massive 


  return (
    <div className='mintDiv'>
         <p>You can find Great Pepe's answer is on the top of the picture</p>   
        <button className='w-button-mint' onClick={() => mintNFT()} disabled={isMintLoading || isLoading}>
          {isLoading || isMintLoading ? "MINTING..." : "MINT NFT"}
        </button>
      
      <br/>
      {tokenId && !isMintLoading && isVisible && <p className='fade-out'>Successfully minted</p>}
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default MintAnswer;
